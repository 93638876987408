html, body, div, main, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, button, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, input, textarea,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
::before, ::after {

    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-decoration: none;
    box-sizing: border-box;

}

/* HTML5 - Older browser support
================================================== */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}


/* BODY
================================================== */
body { line-height: 1; font-family: Agenda;}


/* LISTS - Clear bullets
================================================== */
ol, ul { list-style: none; }


/* QUOTES
================================================== */
blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

/* TABLES
================================================== */
table {
    border-collapse: collapse;
    border-spacing: 0;
}
p, button, input {
  font-size: 14px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
html {
  scroll-behavior: smooth;
  font-family: apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
p, li, a {
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 1.1em;
}
h2 {
  font-size: 2.5rem;
}