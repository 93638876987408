.footer__section {
    width: 100%;
    background-color: #242323;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer__container {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    padding: 2rem 0;
    color: lightgray;
}
.footer__container a {
    margin-bottom: .25rem;
    text-decoration: none;
    color: lightgray
}
.footer__container__item {
    width: 20%;
    display: flex;
    flex-direction: column;
}
.footer__item__section {
    display: flex;
    flex-direction: column;
}
.footer__container__item h3 {
    padding-bottom: 1rem;
    font-size: 1.6rem;
}
.container__logo__header {
    color: #24aaee;
    font-size: 2.4rem !important;
    font-weight: bold;
    padding-bottom: 1rem;
}
.icon__container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.icon__container__icon {
    font-size: 1.9rem !important;
    padding-right: .5rem;
    color: #24aaee !important;
}
.footer__pantents {
    background-color: #1f1e1e;
    padding: .5rem 0;
    width: 100%;
}
.footer__pantents p {
    text-align: center;
    color: lightgray;
    font-size: .9rem;
}
@media only screen and (max-width: 870px) {
    .footer__container {
        flex-wrap: wrap;
    }
    .footer__container__item {
        min-width: 250px;
        margin-top: 1rem;
    }
}
@media only screen and (max-width: 1300px) {
    .footer__container__item {
        align-items: center;
    }
    .footer__item__section {
        text-align: center;
    }
    .footer__container__item p {
        text-align: center;
    }
}